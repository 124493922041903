define('postimusmaximus/models/section', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({

    sectionIndex: attr('string'),
    subSectionIndex: attr('string'),
    title: attr('string'),
    events: hasMany('events', { inverse: 'section', async: true })

  });
});