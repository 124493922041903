define('postimusmaximus/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/section.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/section.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/section.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/section.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});