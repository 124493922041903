define("postimusmaximus/mirage/scenarios/default", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () /* server */{

    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
       Make sure to define a factory for each model you want to create.
    */

    // server.createList('post', 10);
    //

    // var sections = server.createList('section', 50);
    //
    // for (var i = 0; i < sections.length; i++) {
    //   let numberOfEvents = Math.floor(Math.random() * 20) + 1;
    //       // console.log('sectionEvent:', sectionEvent);
    //
    //   server.createList('event', numberOfEvents, { section: sections[i] });
    // }

  };
});