define('postimusmaximus/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({

    date: attr('date'),
    description: attr('string'),
    source: attr('string'),
    section: belongsTo('section', { async: true }),
    subSection: attr('string'),
    displayTitle: attr('string')

  });
});