define('postimusmaximus/components/graph-timeline/component', ['exports', 'ember', 'd3-brush', 'd3-zoom', 'd3-selection', 'd3-time-format', 'd3-scale', 'd3-axis', 'd3-shape', 'd3-array', 'ember-diff-attrs'], function (exports, _ember, _d3Brush, _d3Zoom, _d3Selection, _d3TimeFormat, _d3Scale, _d3Axis, _d3Shape, _d3Array, _emberDiffAttrs) {
      'use strict';

      Object.defineProperty(exports, "__esModule", {
            value: true
      });
      exports.default = _ember.default.Component.extend({
            classNames: 'unified-timeline-navigator-container',
            classNameBindings: ['classId'],

            didInsertElement: function didInsertElement() {
                  this._super.apply(this, arguments);
                  this.prepareGraph();
                  this.updateBrush();
            },

            didUpdateAttrs: (0, _emberDiffAttrs.default)('data', function (changedAttrs) {
                  for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                        args[_key - 1] = arguments[_key];
                  }

                  this._super.apply(this, args);

                  if (changedAttrs && changedAttrs.data) {
                        //  console.log('data didUpdateAttrs!');
                        _ember.default.run.once(this, this.updateBrush);
                  }
            }),

            brushStart: function brushStart() {
                  //  console.log('TIMELINE: brushStart() ACTIVE TARGET', this.activeTarget);
            },

            updateBrush: function updateBrush() {
                  var data = this.get('data');

                  this.x.domain(_d3Array.extent(data, function (d) {
                        return d.date;
                  }));
                  // this.y.domain([0, d3Array.max(data, function(d) { return d.subSectionIndex; })]);
                  this.y.domain([0, _d3Array.max(data, function (d) {
                        return d.sectionIndex;
                  })]);
                  this.x2.domain(this.x.domain());
                  this.y2.domain(this.y.domain());

                  // Focus graph (detail)
                  this.focus.select(".area").datum(data).attr("d", this.area);

                  this.focusDotSelection = this.focus.selectAll(".dot").data(data.filter(function (d) {
                        return d;
                  }));

                  this.focusDotSelection.enter().append("circle").attr("class", "dot").attr("r", 3.5);

                  this.focusDotSelection.attr("cx", this.area.x()).attr("cy", this.area.y1()).attr('class', function (d) {
                        return 'dot stroke' + d.sectionIndex;
                  });

                  this.focusDotSelection.exit().remove();

                  this.focus.select(".axis--x").call(this.xAxis);

                  this.focus.select(".axis--y").call(this.yAxis);

                  // Context graph (thumbnail)
                  this.context.select(".area").datum(data).attr("d", this.area2);

                  this.contextDotSelection = this.context.selectAll(".dot").data(data.filter(function (d) {
                        return d;
                  }));

                  this.contextDotSelection.enter().append("circle").attr("class", "dot").attr("r", 3.5);

                  this.contextDotSelection.attr("cx", this.area2.x()).attr("cy", this.area2.y1());

                  this.contextDotSelection.exit().remove();

                  this.milestoneNominationContext.attr('transform', 'translate(' + this.x2(new Date('07-19-2016')) + ',0)');
                  this.milestoneElectionContext.attr('transform', 'translate(' + this.x2(new Date('11-07-2016')) + ',0)');
                  this.milestoneInaugurationContext.attr('transform', 'translate(' + this.x2(new Date('01-21-2017')) + ',0)');

                  this.context.select(".axis--x").call(this.xAxis2);

                  this.context.select(".brush").call(this.brush).call(this.brush.move, this.x.range());

                  this.svg.select(".zoom").call(this.zoom);
            },

            brushEnded: function brushEnded() {
                  // console.log('TIMELINE: brushEnded()');
            },

            setupWithData: function setupWithData() {
                  // console.log('TIMELINE: setupWithData()');
            },

            prepareGraph: function prepareGraph() {
                  var _this = this;

                  // console.log('TIMELINE: prepareGraph()', this.get('data').length);

                  var data = this.get('data'),
                      emberContext = this;

                  // TODO: Revisit this mechanic for responsive zoom box
                  // d3Selection.select("div.unified-timeline-navigator-container")
                  //      .append("div")
                  //         .classed("svg-container", true) //container class to make it responsive
                  //      .append("svg")
                  //        //responsive SVG needs these 2 attributes and no width and height attr
                  //        .attr("preserveAspectRatio", "xMinYMin meet")
                  //        .attr("viewBox", "0 0 960 300")
                  //        //class to make it responsive
                  //        .classed("svg-content-responsive", true);

                  this.svg = _d3Selection.select("svg");
                  this.margin = { top: 50, right: 20, bottom: 110, left: 40 };
                  this.margin2 = { top: 230, right: 20, bottom: 30, left: 40 };
                  this.width = +this.svg.attr("width") - this.margin.left - this.margin.right;
                  this.height = +this.svg.attr("height") - this.margin.top - this.margin.bottom;
                  this.height2 = +this.svg.attr("height") - this.margin2.top - this.margin2.bottom;

                  this.parseDate = _d3TimeFormat.timeParse("%b %Y");

                  this.x = _d3Scale.scaleTime().range([0, this.width]);
                  this.x2 = _d3Scale.scaleTime().range([0, this.width]);
                  this.y = _d3Scale.scaleLinear().range([this.height, 0]);
                  this.y2 = _d3Scale.scaleLinear().range([this.height2, 0]);

                  this.xAxis = _d3Axis.axisBottom(this.x);
                  this.xAxis2 = _d3Axis.axisBottom(this.x2), this.yAxis = _d3Axis.axisLeft(this.y).tickSize(-this.width);

                  this.brush = _d3Brush.brushX().extent([[0, 0], [this.width, this.height2]]).handleSize(1).on("brush end", function () {
                        return _this.brushed();
                  });

                  this.zoom = _d3Zoom.zoom().scaleExtent([1, Infinity]).translateExtent([[0, 0], [this.width, this.height]]).extent([[0, 0], [this.width, this.height]]).on("zoom", function () {
                        return _this.zoomed();
                  });

                  this.area = _d3Shape.area().curve(_d3Shape.curveStepAfter).x(function (d) {
                        return emberContext.x(d.date);
                  }).y0(this.height).y1(function (d) {
                        return emberContext.y(d.sectionIndex);
                  });

                  this.area2 = _d3Shape.area().curve(_d3Shape.curveStepAfter).x(function (d) {
                        return emberContext.x2(d.date);
                  }).y0(this.height2).y1(function (d) {
                        return emberContext.y2(d.sectionIndex);
                  });

                  this.svg.append("defs").append("clipPath").attr("id", "clip").append("rect").attr("width", this.width).attr("height", this.height);

                  this.focus = this.svg.append("g").attr("class", "focus").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

                  this.context = this.svg.append("g").attr("class", "context").attr("transform", "translate(" + this.margin2.left + "," + this.margin2.top + ")");

                  this.x.domain(_d3Array.extent(data, function (d) {
                        return d.date;
                  }));
                  this.y.domain([0, _d3Array.max(data, function (d) {
                        return d.sectionIndex;
                  })]);
                  this.x2.domain(this.x.domain());
                  this.y2.domain(this.y.domain());

                  this.focus.append("path").datum(data).attr("class", "area").attr("d", this.area);

                  this.focus.selectAll(".dot").data(data.filter(function (d) {
                        return d;
                  })).enter().append("circle").attr("class", "dot").attr("cx", this.area.x()).attr("cy", this.area.y()).attr("r", 3.5);

                  // Milestones
                  this.milestoneNomination = this.focus.append('g').attr('class', 'milestone').attr('transform', 'translate(' + this.x(new Date('07-19-2016')) + ',-30)');
                  this.milestoneNomination.append("line").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.height + 30);
                  this.milestoneNomination.append("text").text(" Nomination");

                  this.milestoneElection = this.focus.append('g').attr('class', 'milestone').attr('transform', 'translate(' + this.x(new Date('11-07-2016')) + ',-20)');
                  this.milestoneElection.append("line").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.height + 20);
                  this.milestoneElection.append("text").text(" Election");

                  this.milestoneInauguration = this.focus.append('g').attr('class', 'milestone').attr('transform', 'translate(' + this.x(new Date('01-21-2017')) + ',-10)');
                  this.milestoneInauguration.append("line").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.height + 10);
                  this.milestoneInauguration.append("text").text(" Inauguration");

                  this.milestoneNominationContext = this.context.append('g').attr('class', 'milestone').attr('transform', 'translate(' + this.x2(new Date('07-19-2016')) + ',0)');
                  this.milestoneNominationContext.append("line").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.height2);

                  this.milestoneElectionContext = this.context.append('g').attr('class', 'milestone').attr('transform', 'translate(' + this.x2(new Date('11-07-2016')) + ',0)');
                  this.milestoneElectionContext.append("line").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.height2);

                  this.milestoneInaugurationContext = this.context.append('g').attr('class', 'milestone').attr('transform', 'translate(' + this.x2(new Date('01-21-2017')) + ',0)');
                  this.milestoneInaugurationContext.append("line").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this.height2);

                  this.focus.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + this.height + ")").call(this.xAxis);

                  this.focus.append("g").attr("class", "axis axis--y").call(this.yAxis);

                  this.context.append("path").datum(data).attr("class", "area").attr("d", this.area2);

                  this.context.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + this.height2 + ")").call(this.xAxis2);

                  this.context.append("g").attr("class", "brush").call(this.brush).call(this.brush.move, this.x.range());

                  this.svg.append("rect").attr("class", "zoom").attr("width", this.width).attr("height", this.height).attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")").call(this.zoom);
            },

            brushed: function brushed() {
                  var emberContext = this;
                  if (_d3Selection.event.sourceEvent && _d3Selection.event.sourceEvent.type === "zoom") {
                        return;
                  } // ignore brush-by-zoom
                  var formerlyS = _d3Selection.event.selection || emberContext.x2.range();
                  emberContext.x.domain(formerlyS.map(emberContext.x2.invert, emberContext.x2));
                  emberContext.focus.select(".area").attr("d", emberContext.area);
                  emberContext.focus.selectAll(".dot").attr("cx", emberContext.area.x()).attr("cy", emberContext.area.y1());
                  emberContext.context.selectAll(".dot").attr("cx", emberContext.area2.x()).attr("cy", emberContext.area2.y1());
                  emberContext.milestoneNomination.attr('transform', 'translate(' + emberContext.x(new Date('07-19-2016')) + ',-30)');
                  emberContext.milestoneElection.attr('transform', 'translate(' + emberContext.x(new Date('11-07-2016')) + ',-20)');
                  emberContext.milestoneInauguration.attr('transform', 'translate(' + emberContext.x(new Date('01-21-2017')) + ',-10)');
                  emberContext.focus.select(".axis--x").call(emberContext.xAxis);
                  emberContext.svg.select(".zoom").call(emberContext.zoom.transform, _d3Zoom.zoomIdentity.scale(this.width / (formerlyS[1] - formerlyS[0])).translate(-formerlyS[0], 0));
                  var rangeStart = emberContext.x.domain(),
                      rangeEnd = emberContext.x(formerlyS[1]);
                  console.log('trouble brush:', [rangeStart, rangeEnd]);
                  emberContext.set('dateLimit', [rangeStart, rangeEnd]);
            },
            zoomed: function zoomed() {
                  var emberContext = this;
                  if (_d3Selection.event.sourceEvent && _d3Selection.event.sourceEvent.type === "brush") {
                        return;
                  } // ignore zoom-by-brush
                  var formerlyT = _d3Selection.event.transform,
                      formerlyS = formerlyT || emberContext.x2.range();
                  emberContext.x.domain(formerlyT.rescaleX(emberContext.x2).domain());
                  emberContext.focus.select(".area").attr("d", emberContext.area);
                  emberContext.focus.select(".axis--x").call(emberContext.xAxis);
                  emberContext.focus.selectAll(".dot").attr("cx", emberContext.area.x()).attr("cy", emberContext.area.y1());
                  emberContext.context.selectAll(".dot").attr("cx", emberContext.area2.x()).attr("cy", emberContext.area2.y1());
                  emberContext.milestoneNomination.attr('transform', 'translate(' + emberContext.x(new Date('07-19-2016')) + ',-30)');
                  emberContext.milestoneElection.attr('transform', 'translate(' + emberContext.x(new Date('11-07-2016')) + ',-20)');
                  emberContext.milestoneInauguration.attr('transform', 'translate(' + emberContext.x(new Date('01-21-2017')) + ',-10)');
                  emberContext.context.select(".brush").call(emberContext.brush.move, emberContext.x.range().map(formerlyT.invertX, formerlyT));
                  var rangeStart = emberContext.x.domain(),
                      rangeEnd = emberContext.x(formerlyS[1]);
                  console.log('trouble zoom:', [rangeStart, rangeEnd]);
                  emberContext.set('dateLimit', [rangeStart, rangeEnd]);
            }
      });
});