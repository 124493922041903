define('postimusmaximus/mirage/factories/section', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    sectionIndex: function sectionIndex(i) {
      return i;
    },
    title: function title() {
      return _emberCliMirage.faker.company.companyName();
    }
  });
});