define('postimusmaximus/routes/application', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    model: function model() {
      return _ember.default.RSVP.hash({
        events: this.get('store').findAll('event'),
        sections: this.get('store').findAll('section')
      });
    }
  });
});