define('postimusmaximus/mirage/factories/event', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    date: function date() {
      return _emberCliMirage.faker.date.recent(2000);
    },
    description: function description() {
      return _emberCliMirage.faker.hacker.phrase();
    },
    source: function source() {
      return _emberCliMirage.faker.internet.url();
    }
  });
});