define('postimusmaximus/helpers/format-date-human', ['exports', 'ember', 'd3-time-format'], function (exports, _ember, _d3TimeFormat) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatDateHuman = formatDateHuman;
    function formatDateHuman(params /*, hash*/) {
        var inputDate = params[0],
            innerD3TimeFormat = _d3TimeFormat.timeFormat("%B %d, %Y"),
            parsedDate = new Date(inputDate);

        var formattedDate = '' + innerD3TimeFormat(parsedDate);

        return formattedDate;
    }

    exports.default = _ember.default.Helper.helper(formatDateHuman);
});